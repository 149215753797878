import { render, staticRenderFns } from "./pricelist.vue?vue&type=template&id=201d124a&"
import script from "./pricelist.vue?vue&type=script&lang=js&"
export * from "./pricelist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('201d124a')) {
      api.createRecord('201d124a', component.options)
    } else {
      api.reload('201d124a', component.options)
    }
    module.hot.accept("./pricelist.vue?vue&type=template&id=201d124a&", function () {
      api.rerender('201d124a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8YrLLVuM3WJ6V/JS/comp/pricelist.vue"
export default component.exports